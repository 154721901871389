
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input {
  transition: all 0.1s ease-in;
}

body {
  background-color: #7ebfff;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

body.fixed {
  overflow: hidden;
}

a {
  text-decoration: none;
  color:#000;
}

button {
  cursor: pointer;
  border: none;
  color: #000;
}

input {
  border: none;
}

input:focus {
  outline: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 550px;
  padding: 30px;
  border: 2px solid #000;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: 8px 8px 1px rgba(0, 0, 0, 0.8);
  overflow: auto;

}

.todo-form {
  display: flex;
  width: 100%;
  /* height: 42px; */
  gap: 10px;
  margin-top: 12px;
}

.todo-form > input {
  flex: 7;
  padding: 14px;
  border-radius: 5px;
  border: 2px solid #000;
}

.todo-form > button {
  flex: 1;
  border-radius: 10px;
}

.add-btn {
  background-color: black;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-btn::before,
.add-btn::after {
  content: "";
  position: absolute;
  background-color: #fff;
}

.add-btn::before {
  width: 30%;
  height: 2px;
}

.add-btn::after {
  width: 2px;
  height: 30%;
}

.filter-btn {
  display: flex;
  gap: 5px;
  margin: 16px 0;
}

.filter-btn > button {
  flex: 1;
  border-radius: 5px;
  padding: 14px 0;
  background-color: #fff;
  color: #000;
}

.filter-btn > button:hover {
  background-color: #000;
  color: #fff;
}

.filter-btn > button:focus {
  outline: 2px solid #000;
}

.filter-btn > button.pressed {
  background-color: #000;
  color: #fff;
}

.list-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 290px;
  overflow-y: auto;
}

/* 스크롤바 너비 */
.list-wrap::-webkit-scrollbar {
  width: 18px;
}

/* 스크롤바 길이 스타일*/
.list-wrap::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 10px;
}

/* 스크롤바 배경 */
.list-wrap::-webkit-scrollbar-track {
  background-color: #d9d9d9;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 5px;
  height: auto;
  padding: 20px 10px;;
  border: 1px solid #aeaeae;
  background-color: #fff;
  border-radius: 5px;
}

.list-item > input {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  appearance: none;
  background-color: #ffffff;
  border: 2px solid #959595;
}

.list-item > input:checked {
  /* background-color: #c93939; */
  position: relative;
}

.list-item > input:checked::before,
.list-item > input:checked::after {
  content: "";
  position: absolute;
  background-color: #c93939;
  border-radius: 5px;
}

.list-item > input:checked::before {
  top: calc( 50% - 3px);
  left: 6px;
  width: 3px;
  height: 9px;
  transform: rotate(135deg);
}

.list-item > input:checked::after {
  top: calc( 50% - 6px);
  left: 12px;
  width: 3px;
  height: 12px;
  transform: rotate(-135deg);
}

.list-item > label {
  flex: 1;
  font-size: 16px;
  max-height: 48px;
  overflow: hidden;
}

.list-btn > button {
  padding: 6px 18px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
}

.list-btn > button:first-child {
  background-color: #353434;
}

.list-btn > button:last-child {
  background-color: #c93939;
  margin-left: 5px;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip-path: rect(1px 1px 1px 1px);
  clip-path: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.form-group {
  flex : 1;
}

.edit-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.todo-text {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 4px 8px;
}

.edit-modal {
  width: 100%;
  height: 20vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #004182;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.edit-modal > p {
  font-size: 16px;
  font-weight: 600;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 8px;
}

.login-form > input[type="text"],
.login-form > input[type="password"] {
  width: 100%;
  padding: 12px;
  border: 2px solid #000000;
  border-radius: 10px;
  font-size: 14px;
}

.row-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.row-wrap .keep-check {
  display: flex;
  align-items: center;
  gap: 6px;
}

.keep-check > input[type="checkbox"] {
  width: 22px;
  height: 22px;
}


.login-form button {
  padding: 15px 0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  background-color: #000000;
  color: #fff;
}

/* .login-form button:last-child {
  border:2px solid #000;
  background-color: #fff;
  color: #000;
} */

/* 회원가입 CSS */
.back-btn {
  background-color: transparent;
  margin-bottom: 10px;
}

.back-btn > span {
  font-size: 42px;
  color: #000;
}

.join-form {
  width: 440px;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f1f1f1;
  box-shadow: 4px 4px 6px 1px rgba(174, 174, 174, 0.5);
  border: 2px solid #000;
}

.join-form > h2 {
  margin-bottom: 18px;
}

.join-input-inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.join-input-inner > input[type="text"],
.join-input-inner > input[type="password"] {
  width: 100%;
  padding: 12px;
  border-bottom: 2px solid #000;
  font-size: 14px;
}

.label-wrap {
  display: flex;
  gap: 4px;
  align-items: center;
}

.label-wrap > label {
  font-weight: 600;
  font-size: 15px;
}

.label-wrap > span{
  font-size: 12px;
}

.label-wrap > span.red {
  color: #da0000;
}

.label-wrap > span.green {
  color: #00c324;
}
.btn-wrap {
  display: flex;
  gap: 4px;
  margin-top: 20px;
}

.btn-wrap > button {
  flex: 1;
  padding: 15px 0;
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  color:#fff;
}

/* AlertModal CSS */ 
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  animation: fadeOut 0.25s ease-out forwards;
}

.modal-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  color: #000;
  padding: 40px 50px;
  width: 440px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 5px;
  animation: slideDown 0.25s ease-out forwards;
}

.modal-bg.open {
  animation-name: fadeIn;
}

.modal.open .modal-wrap{
  animation-name: slideUp;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-content .notice {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}

.modal-btn {
  display: flex;
  gap: 8px;
}

.modal-btn > button {
  flex: 1;
  padding: 8px 0;
  border-radius: 5px;
  font-size: 14px;
}

.modal-btn > button:first-child {
  background-color: #ffffff;
  border: 1px solid #000;
}
.modal-btn > button:last-child {
  background-color: #000;
  color: #fff;
}

.todo-count {
  margin-top: 16px;
}

/* 모달창 애니메이션 */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes slideUp {
  from {
    transform: translateX(-50%, -50%);
  }
  to {
    transform: translateX(-50%);
  }
}
@keyframes slideDown {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(-50%, -50%);
  }
}


@media screen and (max-width: 480px){
  .wrapper {
    width: 90vw;
    height: 460px;
    margin: 0 auto;
    padding: 20px;
  }
  h2 {
    font-size: 22px;
  }
  .todo-form {
    /* height: 50px; */
    gap: 5px;
  }
  .todo-form > input {
    flex: 6 1;
    font-size: 14px;
  }
  .filter-btn > button {
    font-size: 12px;
    padding: 12px 0;
  }
  .list-wrap {
    height: 280px;
  }
  .list-item {
    padding: 14px 12px;
  }
  .list-item > input {
    width: 20px;
    height: 20px;
  }
  .list-item > input:checked::before {
    top: calc( 50% - 3px);
    left: 3px;
  }
  .list-item > input:checked::after {
    top: calc( 50% - 6px);
    left: 9px;
  }
  .list-btn > button {
    padding: 6px 10px;
    font-size: 14px;
  }
  .todo-count {
    font-size: 14px;
  }
  .list-item > label {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .todo-form {
    height: 50px;
  }
  .todo-form > input {
    flex: 4 1;
    font-size: 12px;
  }
  .list-wrap {
    height: 220px;
  }
  .login-form,.join-form {
    width: 320px;
  }
  .join-form > h2 {
    margin-bottom: 14px;
  }
  .btn-wrap {
    margin-top: 14px;
  }
  .modal-wrap {
    width: 300px;
    padding: 20px;
  }
  .modal-content .notice {
    font-size: 16px;
  }
}

@media screen and (max-width: 320px) {
  h2 {font-size: 18px}
  .todo-form { height: 48px;}
  .todo-form > input {
    flex: 3 1;
    padding: 8px;
  }

  .filter-btn > button {
    font-size: 10px;
  }
  .login-form,.join-form {
    width: 290px;
    padding: 20px;
  }

  .join-form > h2 {
    font-size: 18px;
  }
  .modal-wrap {
    width: 280px;
    padding: 20px;
  }

  
}